ul {
  list-style-type: none !important;
}

ul li {
  color: #fff;
  font-family: 'avenir_nextregular' !important;
  font-size: 16px !important;
}
ul li a {
  text-decoration: none !important;
  color: #fff !important;
}
